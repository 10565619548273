<template>
    <div class="content md" style="position: relative">
        <buddhism-calendar show-out-month />
        <a-button type="primary" class="export-buddhism" @click="exportData" :loading="exporting" v-if="admin">{{exporting ? '正在导出' : '本月佛事预订导出'}}</a-button>
    </div>
</template>

<script>
import {downLoadBlobFile} from "../../common/js/tool";
import BuddhismCalendar from "../../layouts/buddha/BuddhismCalendar";
import {isBuddhaAdmin} from "../../common/constant/org";

export default {
    name: "BuddhaBoard",
    components: {
        BuddhismCalendar
    },
    data() {
        return {
            exporting: false,
            admin: isBuddhaAdmin(),
        }
    },
    methods: {
        exportData() {
            if(this.exporting) return;
            this.exporting = true;
            let fileName = "佛事数据";
            const date = new Date(this.current);
            date.setDate(1);
            const start = date.pattern("yyyy-MM-dd");
            date.setMonth(date.getMonth() + 1);
            date.setDate(date.getDate() - 1);
            const end = date.pattern("yyyy-MM-dd");
            let url = `/admin/fs/export?begin_time=${start}&end_time=${end}`;
            this.$axios(url, {noTempleFilter: true, responseType: 'blob'}).then(res => {
                this.exporting = false;
                downLoadBlobFile(res, fileName);
            }).catch(() => {
                this.exporting = false;
            });
        },
    }
}
</script>

<style scoped lang="less">
    .export-buddhism {
        position: absolute;
        top: 32px;
        right: 32px;
        z-index: 1;
    }
</style>
